html {
    font-size: 100%;
    box-sizing: border-box;
}

html, body{
    height: 100%;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    line-height: 1.3;
    padding-top: 95px;
    overflow-x: hidden;
}

.dropdown-toggle::after {
    display: none !important;
}

img {
    width: 100%;
}

//Patterns
.imgShapeCanary {
    max-width: 5.044rem
}

.imgShapeJungle {
    max-width: 5.963rem;
}

.imgShapeGrey {
    max-width: 6.688rem;
}

//Content
.page_content {
    width: 100%;
    padding: 60px 0;

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-Black);
        background-color: var(--bs-Canary);
        font-weight: 600;
        border: none;
        position: relative;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -10px;
            border: 10px solid transparent;
            border-top-color: var(--bs-Canary);
        }

        &:after {
            left: 50%;
            border: 0 solid transparent;
            border-bottom-color: var(--bs-White);
        }
    }

    .tab-pane {
        p {
            font-size: 1rem;
            font-weight: 200;
        }
    }

    &__title_wrapper {
        position: relative;
        width: 100%;

        h2 {
            font-size: 1.75rem;
            font-weight: 700;
            text-align: right;
            margin: 0;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: -10px;
                width: 75px;
                border-bottom: 3px solid var(--bs-Pineapple);
            }
        }

        img {
            position: absolute;
            top: -30px;
            left: 65px;
        }
    }
    &__text_wrapper {
        position: relative;
        width: 100%;

        p {
            font-size: 1.063rem;
            font-weight: 300;
            color: var(--bs-DarkGrey);
            padding: 0 25px;
        }
    }
    &__super_title {
        font-size: 1.75rem;
        position: relative;
        padding: 10px 20px;
        font-weight: 600;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 18%;
            height: 25%;
            border-left: 5px solid var(--bs-Pineapple);
        }
    }

    &__inner {
        padding: 30px 0;

        h2 {
            font-size: 2.188rem;
            color: var(--bs-DarkGreyVariant);
            text-transform: uppercase;
            font-weight: 600;
            padding: 0 30px 0 0;
        }

        &__our_v {
            padding: 30px 0;
            border-bottom: 1px solid var(--bs-GreyLong);

            p {
                font-size: 1rem;
                line-height: 2rem;
                margin-bottom: 3.125rem;
                color: var(--bs-DarkGrey);
                font-weight: 300;

                &:nth-child(3n+3) {
                    margin-bottom: 0;
                }
            }
        }

        &__our_p {
            padding: 30px 0 0 0;

            p {
                font-size: 1rem;
                line-height: 2rem;
                margin-bottom: 3.125rem;
                color: var(--bs-DarkGrey);
                font-weight: 300;

                &:nth-child(3n+3) {
                    margin-bottom: 0;
                }
            }

            ul {
                list-style: none; /* Remove default bullets */
                li {
                    margin-bottom: 1rem;
                }
            }

            ul li::before {
                content: "\2022";
                color: var(--bs-DarkGrey);
                font-weight: 400;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: 1rem;
            }
        }
    }
    .super_title_uppercase {
        text-transform: uppercase;
        font-size: 1.563rem;
        position: relative;
        margin-left: 15px;
        font-weight: 700;

        &::after {
            content: '';
            position: absolute;
            left: -15px;
            top: 18%;
            height: 65%;
            border-left: 5px solid #F2C94C;
            border-left: 5px solid var(--bs-Pineapple);
        }
    }
}

.video-overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.80);
    opacity: 0;
    transition: all ease 500ms;
}

.video-overlay.open {
    position: fixed;
    z-index: 1000;
    opacity: 1;
}

.video-overlay-close {
    position: absolute;
    z-index: 1000;
    top: 15px;
    right: 20px;
    font-size: 36px;
    line-height: 1;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: all 200ms;
}

.video-overlay-close:hover {
    color: var(--bs-Canary);
}

.video-overlay iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    /* width: 90%; */
    /* height: auto; */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
}
