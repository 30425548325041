.pageProposal {
    .page_content {
        &__proposal_slider {
            width: 100%;
            padding: 30px 0 0 0;

            &__cell {
                width: 100%;
                height: 480px;
                margin-right: 10px;
                background: var(--bs-LightGreyVariant100);
                counter-increment: carousel-page-cell;
                position: relative;
                z-index: 11;

                &__bg {
                    width: 100%;
                    height: 480px;
                    position: relative;
                    overflow: hidden;
                    z-index: 10;

                    img {
                        width: 100%;
                        height: 480px;
                        object-fit: cover;
                    }
                }

                &__content {
                    width: 100%;
                    height: 400px;
                    padding: 30px;
                    margin-left: -120px;
                    background-color: var(--bs-White);
                    position: relative;
                    z-index: 11;

                    &__inner-blank{
                        width: 100%;
                        overflow: hidden;
                        position: relative;
                        h2 {
                            font-size: 1.563rem;
                            text-transform: uppercase;
                            font-weight: 600;
                            position: relative;
                            margin-bottom: 15px;

                            &::after {
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: -5px;
                                height: 5px;
                                width: 1.875rem;
                                border-bottom: 5px solid var(--bs-Pineapple);
                            }
                        }
                    }
                    p {
                        font-size: 1rem;
                        color: var(--bs-GreyLong);
                        font-weight: 300;
                        word-wrap: break-word;
                    }
                }
            }


            .flickity-button {
                position: absolute;
            }

            .flickity-prev-next-button.previous {
                width: 70px;
                height: 70px;
                border-radius: 0;
                background-color: var(--bs-BlueBerry);
                color: var(--bs-White);
            }

            .flickity-prev-next-button.next {
                width: 70px;
                height: 70px;
                border-radius: 0;
                background-color: var(--bs-Pineapple);
                z-index: 11;
            }

            .flickity-prev-next-button .flickity-button-icon {
                position: absolute;
                left: 50%;
                margin-left: -10px;
                top: 50%;
                margin-top: -20px;
                width: 20px;
            }

            .flickity-page-dots {
                counter-reset: a;
                top: 0;
                right: 0;
                width: auto;
            }

            .flickity-page-dots .dot::after {
                content: counter(a);
                counter-increment: a;
            }

            .flickity-page-dots .dot {
                width: initial;
                height: initial;
                background-color: transparent;
                font-size: 2.188rem;
                font-weight: 700;
            }

            .flickity-page-dots .dot.is-selected {
                font-size: 4.375rem;
                color: var(--bs-BlueBerry);
            }
        }

        &__proposal_features {
            width: 100%;
            padding: 60px;
            background-color: var(--bs-LightGreyVariant100);
            position: relative;
            border-bottom-right-radius: 100% 15%;
            border-bottom-left-radius: 100% 15%;
            overflow: hidden;

            img {
                width: 100%;
            }

            h2 {
                padding: 0 0.625rem;
                font-size: 1.25rem;
                text-transform: uppercase;
                border-left: 10px solid var(--bs-Pineapple);
                margin: 30px 0;
                font-weight: 600;
            }

            p {
                font-size: 1rem;
                font-weight: 300;
                margin-bottom: 1rem;
                padding: 0 20px;
            }
        }

        &__proposal_testimonials {
            width: 100%;
            padding: 60px 0;

            &__title_wrapper {
                position: relative;
                padding: 60px 0;
                background: url(../images/quote.svg) no-repeat center;

                h2 {
                    text-align: center;
                    font-size: 2rem;
                    line-height: 2.2rem;
                    font-weight: 600;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        margin-left: -25px;
                        margin-bottom: 30px;
                        bottom: -40px;
                        height: 5px;
                        width: 3.125rem;
                        border-bottom: 5px solid var(--bs-Pineapple);
                    }
                }

                p {
                    text-align: center;
                    font-size: 1rem;
                    color: var(--bs-DarkGrey);
                    margin-top: 20px;
                    font-weight: 300;
                }
            }
        }
    }
}
