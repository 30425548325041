@media (min-width: 280px) and (max-width: 768px) {
    .home_section_testimonials {
        padding: 3rem 0 !important;

        .testimonial-cell {
            .testimonial-text {
                font-size: 15px;
            }
        }
    }
}
