.pageHistory {
    .page_content {

        &__image {
            padding: 30px 0;
        }

        .events-content {
            list-style: none;
            margin: 0;
            padding: 0;

            &__image {
                width: 100%;
                height: 425px;
                background-color: var(--bs-LightGrey);

                img {
                    width: 100%;
                    height: 425px;
                    object-fit: cover;
                }
            }

            &__content {
                width: 100%;
                height: 425px;
                padding: 10px 40px;
                background-color: var(--bs-BlueBerry);

                p {
                    font-size: 0.875rem;
                    text-align: left;
                    color: var(--bs-White);
                    font-weight: 200 !important;
                }

                h2 {
                    text-align: left;
                    color: var(--bs-White);
                }
            }
        }

        .horizontal-timeline .events-content li[data-date], .horizontal-timeline .events-content li[data-horizontal-timeline] {
            border-radius: 0;
            background-color: var(--bs-White);
        }

        &__timeline {
            width: 100%;
            background-color: var(--bs-LightGreyVariant100);
            padding: 60px 0;

            .carousel-cell {
                width: 100%;
                height: 600px;
                margin-right: 10px;
            }


            &__image {
                width: 100%;
                height: 400px;
                background-size: cover;
                background-position: center;
            }

            &__year {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 3rem;

                &::after {
                    content: '';
                    border-top: 1px solid;
                    margin: 0 10px 0 10px;
                    flex: 1 0 20px;
                    color: var(--bs-GreyLong);
                }
            }

            &__month {
                text-transform: uppercase;
                font-size: 0.875rem;
                font-weight: 700;
            }

            &__description {
                margin-top: 1rem;
                font-size: 0.875rem;
            }
        }
    }
}
