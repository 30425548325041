//---------------------------------------------
//
//  Variáveis
//
//    1. Fontes do Bootstrap
//    2. Globais do Bootstrap
//    3. Cores do Bootstrap
//    4. Largura do container do Bootstrap
//    5. Cookies
//
//---------------------------------------------

//---------------------------------------------
// 1. Fontes do Bootstrap
//---------------------------------------------
$font-family-base: ProximaNova, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Globais do Bootstrap
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Cores do Bootstrap
//---------------------------------------------
$body-color: #000; // The default text color

$primary: #1E3799;
$secondary: #6c757d;
$success: #27AE60;
$info: #0dcaf0;
$warning: #FFCB30;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

$LightBlue: #7AADCB;
$CornFlower: #4589B0;
$BlueBerry: #1E3799;
$Denim: #0C2461;
$Canary: #FFCB30;
$Pineapple: #F2C94C;
$Jungle: #27AE60;
$GreyLong: #507274;
$Black: #000;
$DarkGrey: #3A5455;
$DarkGreyVariant: #507274;
$DarkGreyExtra: #606060;
$LightGrey: #eee;
$LightGreyVariant: #888;
$LightGreyVariant100: #f5f5f5;
$White: #fff;

$colors: (
    "LightBlue": $LightBlue,
    "CornFlower": $CornFlower,
    "BlueBerry": $BlueBerry,
    "Denim": $Denim,
    "Canary": $Canary,
    "Pineapple": $Pineapple,
    "Jungle": $Jungle,
    "GreyLong": $GreyLong,
    "DarkGrey": $DarkGrey,
    "DarkGreyVariant": $DarkGreyVariant,
    "DarkGreyExtra": $DarkGreyExtra,
    "Black": $Black,
    "LightGrey": $LightGrey,
    "LightGreyVariant": $LightGreyVariant,
    "LightGreyVariant100": $LightGreyVariant100,
    "White": $White
);

// Accordion
$accordion-button-icon: url("data:image/svg+xml, <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='20' height='20' rx='10' fill='#FFCB30'/><path d='M15 9.16667H10.8333V5H9.16667V9.16667H5V10.8333H9.16667V15H10.8333V10.8333H15V9.16667Z' fill='black'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='20' height='20' rx='10' fill='#FFCB30'/> <path d='M5 8.75H15V11.25H5V8.75Z' fill='black'/></svg>");

//---------------------------------------------
// 4. Largura do container do Bootstrap
//---------------------------------------------

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);

//---------------------------------------------
// 4. Largura do container do Bootstrap
//---------------------------------------------
$cb_scheme_color: $Canary;
$cb_msg_color: #000000;
