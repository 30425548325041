.pageSinglePost {
    .carousel-event {
        margin-top: 40px;
    }

    .carousel-cell {
        width: 100%;
        height: 400px;
        margin-right: 10px;
        border-radius: 5px;
        counter-increment: carousel-cell;
    }

    .carousel-nav-event .carousel-cell {
        width: 25%;
        height: 150px;

        img {
            width: 100%;
            height: 150px;
            object-fit: cover;
            opacity: 0.3;
            border-radius: 7px;
        }
    }

    .carousel-nav-event .carousel-cell.is-nav-selected {
        img {
            width: 100%;
            height: 150px;
            object-fit: cover;
            opacity: 1;
        }
    }
}
