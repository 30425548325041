@media (min-width: 280px) and (max-width: 510px) {
    .page_content {
        &__differentials {
            .nav-pills{
            }
            .nav-pills .nav-link {
                width: 100%;
                flex-basis: 0;
                flex-grow: 1;
                text-align: center;
            }
        }
        &__events {
            &__content {
                .carousel {
                    .carousel-cell {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .page_content {
        &__inner {
            &__intro{
                h2{
                    font-size: 1.5rem;
                }
            }
        }
    }
}
