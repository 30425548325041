@media (min-width: 280px) and (max-width: 768px) {
    #navbar {
        border: none;
        background-color: var(--bs-White);

        .navbar-nav {
            margin-top: 20px;
        }

        .objetivo-horizontal-obj:before {
            color: var(--bs-BlueBerry);
        }

        .btn_study_with_us {
            width: 100% !important;
            display: block !important;
            margin: 0 !important;
        }

        .btn_student_area {
            width: 100% !important;
            display: block !important;
            margin: 0 !important;
        }

        .dropdown-toggle{
            img{
                float: right !important;
                line-height: 30px !important;
            }
            svg{
                float: right !important;
            }
        }

        li {
            padding: 25px 0 !important;
            border-top: 1px solid rgba(30, 55, 153, .3);

            ul li {
                border: 0 !important;
                padding: 0 !important;
                margin-bottom: 5px !important;

                a {
                    background-color: var(--bs-LightGrey) !important;
                    border: none !important;
                    text-align: center !important;
                }
            }

            .nav-link {
                //padding: 0 0.3rem;
                //font-weight: 500;
                //font-size: 1.087rem;
                color: var(--bs-DarkGrey) !important;
                img{
                    float: right !important;
                }
            }
        }
    }
}

@media (min-width: 1000px) and (max-width: 1024px) {
    .navbar {
        .objetivo-horizontal-obj:before {
            content: "\e900";
            color: var(--bs-BlueBerry);
            font-size: 2rem;
        }


        li {
            padding: 0 0.3rem;

            .nav-link {
                padding: 0 0.3rem;
                font-weight: 500;
                font-size: 0.75rem;
                color: var(--bs-GreyLong) !important;
            }
        }
    }
}
