@media (min-width: 280px) and (max-width: 510px) {
    .pageHero {

        .container {
            h2 {
                font-size: 2.5rem;
            }
        }
    }

    .pageProposal {
        .page_content {
            &__proposal_slider {

                &__cell {
                    height: auto;

                    &__content {
                        margin-left: initial;
                        height: auto;
                    }

                }


                .flickity-button {
                    display: none;
                }
            }

            &__proposal_features {
                padding: 0;
            }
        }
    }

    .pageHistory {
        .page_content {

            &__image {
                padding: 30px 0;
            }

            .events-content {

                &__image {
                    height: auto;

                    img {
                        height: auto;
                    }
                }

                &__content {
                    padding: 10px 20px;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1000px) {

}
