.pageMissionAndValues {
    .page_content {

        &__img_sp {
            img {
                width: 100%;
                height: 500px;
                object-fit: cover;
            }
        }

        &__values_and_differentials {
            width: 100%;
            padding: 30px 0;

            &__content {
                font-weight: 300;
                letter-spacing: .050rem;
                color: var(--bs-DarkGrey);
            }
        }

        &__other_pages {
            img {
                width: 100%;
            }

            padding: 60px 0;

            h2 {
                padding: 0 0.625rem;
                font-size: 1.25rem;
                text-transform: uppercase;
                border-left: 10px solid var(--bs-Jungle);
                margin: 30px 0;
                font-weight: 600;
            }

            &__main_desc {
                font-size: 1.25rem;
                font-weight: 200;
                margin-bottom: 2.5rem;
                padding: 0 20px;
            }

            &__main_short_desc {
                font-size: 0.938rem;
                font-weight: 400;
                margin-bottom: 3.75rem;
                padding: 0 20px;
            }

            a {
                width: 150px;
                background-color: var(--bs-Jungle);
                color: var(--bs-White);
                margin: 0 20px;

                &:hover {
                    color: var(--bs-White);
                }
            }
        }
    }
}
