.pageHeroPost {
    height: 30vh;
    position: relative;

    img {
        object-fit: cover;
        width: 100%;
        min-width: 100%;
        height: 30vh;
        position: absolute;
        top: 0;
        left: 0;
    }

    .pattern {
        width: 100%;
        height: 30vh;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7) !important;
        z-index: 11;
    }

    .container {
        position: relative;
        z-index: 12;
        overflow: hidden;
        color: var(--bs-White);

        h2 {
            word-wrap: break-word;
            font-size: 3.125rem;
            line-height: 3.438rem;
            font-weight: 700;
            font-style: normal;
            text-transform: none;
            text-decoration: none;
        }

        .arrows {
            white-space: nowrap;
        }

        .arrows li {
            display: inline-block;
            line-height: 26px;
            margin: 0 9px 0 -10px;
            padding: 0 20px;
            position: relative;
        }

        .arrows li::before,
        .arrows li::after {
            border-right: 1px solid var(--bs-LightGreyVariant100);
            content: '';
            display: block;
            height: 50%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            z-index: -1;
            transform: skewX(45deg);
        }

        .arrows li::after {
            bottom: 0;
            top: auto;
            transform: skewX(-45deg);
        }

        .arrows li:last-of-type::before,
        .arrows li:last-of-type::after {
            display: none;
        }

        .arrows li a {
            font-size: 1.2rem;
            letter-spacing: -1px;
            text-decoration: none;
        }

        .arrows li:nth-of-type(1) a {
            color: hsl(0, 0%, 100%);
        }

        .arrows li:nth-of-type(2) a {
            color: hsl(0, 0%, 95%);
        }

        .arrows li:nth-of-type(3) a {
            color: hsl(0, 0%, 85%);
            pointer-events: none;
            cursor: default;
            text-decoration: none;
        }
    }
}

.pageSinglePost {
    padding: 0 0 120px 0;

    h1 {
        font-size: 3rem;
        font-weight: 900;
        margin-bottom: 30px;
    }

    img {
        margin-bottom: 30px;
    }

    p {
        font-size: 1.125rem;
        font-weight: 300;
        word-wrap: break-word;
        letter-spacing: 0.050rem;
        color: var(--bs-Black);
    }

    &__infoData {
        width: 100%;
        padding: 20px 0;
        margin-bottom: 30px;
        border-top: 1px solid rgba(0, 0, 0, .2);
        border-bottom: 1px solid rgba(0, 0, 0, .2);

        span {
            font-size: 0.875rem;
            font-weight: 300;
            color: var(--bs-GreyLong);
        }

        #share {
            border: none;
            background-color: transparent;
        }
    }
}
