.pageStudyWithUs {
    .page_content {
        &__boxes_register {
            width: 100%;
            padding: 60px 0;

            &__onLink {
                .card {
                    width: 100%;
                    border: none;
                    height: 220px;
                }

                &__bgImg {
                    width: 100%;
                    height: 220px;
                    position: relative;

                    .pattern {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: rgba(86, 86, 86, .5);
                        z-index: 10;
                    }

                    a {
                        position: absolute;
                        transition: ease all .5s;
                        top: 70%;
                        left: 50%;
                        margin-left: -75px;
                        z-index: 11;
                        display: block;
                        width: 150px;
                        height: 40px;
                        line-height: 37px;
                        text-decoration: none;
                        text-transform: uppercase;
                        color: var(--bs-White);
                        text-align: center;
                        border: 2px solid var(--bs-White);

                        &:hover {
                            background-color: var(--bs-Canary);
                            border-color: var(--bs-Canary);
                        }
                    }
                }
            }
        }
    }
}
