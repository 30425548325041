.home_section_testimonials {
  padding: 6rem 0;
  width: 100%;

  .section-home-testimonials {
    margin-bottom: 40px;
    position: relative;

    .flickity-prev-next-button.previous {
      top: 250px;
    }

    .flickity-prev-next-button.next {
      top: 250px;
    }
  }

  .testimonial-cell {
    width: 100%;
    height: 620px;
    margin-right: 10px;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    counter-increment: testimonial-cell;

    &--student-inline-container {
      width: 100%;
      padding: 10px 0;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(109.6deg, rgba(62, 161, 219, 1) 11.2%, rgba(93, 52, 236, 1) 100.2%);
      position: relative;
      overflow: hidden;

      &--student-feature {
        position: relative;
        width: 100%;
        height: inherit;

        .with-center-image {
          width: 100%;
          max-width: 280px;
          left: 50%;
          margin-left: -140px;
          position: absolute;
          bottom: -60px;
        }
        .with-no-center-image {
          width: 100%;
        }
      }

      .divider-testimonial {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
      }

      .divider-testimonial svg {
        position: relative;
        display: block;
        width: calc(123% + 1.3px);
        height: 140px;
      }

      .divider-testimonial .shape-fill {
        fill: var(--bs-White);
      }
    }

    .testimonial-text {
      font-size: 20px;
      font-weight: 300;
    }

    .testimonial-name {
      font-size: 20px;
      font-weight: 700;
    }

    .testimonial-acc {
      font-size: 15px;
      font-weight: 200;
    }
  }

  .testimonial-nav-container {
    padding: 20px 20px;
    width: 80%;
    margin: -80px auto;
    border-radius: 10px;
    background-color: var(--bs-White);
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.15) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.2) 0px 30px 60px -30px;
  }

  .testimonial-nav .testimonial-cell {
    height: auto;
    width: 100px;
    border: none;
    box-shadow: initial;
    margin-right: 20px;
    opacity: 0.5;

    .testimonial-thumb-content {
      .cbox {
        width: 100%;
        height: 100px;
        background-color: var(--bs-Canary);
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        img {
          position: absolute;
          max-width: 90px;
          bottom: -40px;
          left: 50%;
          margin-left: -45px;
        }
      }

      .testimonial-name-cb {
        font-size: 15px;
        text-align: center;
        font-weight: 700;
        margin-top: 5px;
      }

      .testimonial-acc-cb {
        text-align: center;
        display: table;
        font-size: 14px;
        font-weight: 300;
        margin: 0 auto;
      }
    }
  }

  .testimonial-nav .testimonial-cell.is-nav-selected {
    opacity: 1;

    .testimonial-thumb-content {
      .cbox {
        background-color: var(--bs-Canary);
      }
    }
  }

  &__description {
    &__introTitle {
      text-transform: uppercase;
      font-size: 1.25rem;
      font-weight: 500;
      color: var(--bs-DarkGrey);
      position: relative;
      margin-top: 1.875rem;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: -0.625rem;
        height: 1px;
        width: 35px;
        border-bottom: 1px solid var(--bs-DarkGrey);
      }
    }

    &__title {
      font-size: 2.188rem;
      font-weight: 600;
      margin-top: 1.875rem;
      margin-bottom: 1.875rem;
    }

    &__text {
      font-size: 1.125rem;
      font-weight: 300;
      margin-bottom: 2.5rem;
      color: var(--bs-DarkGrey);
    }

    &__small_ratings {
      color: var(--bs-Pineapple);
      margin-bottom: 1.25rem;
    }

    &__rateData {
      font-size: 1rem;
      letter-spacing: 0.1rem;
      margin: 0;
      font-weight: 200;
    }
  }
}
