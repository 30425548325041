.home_section_campuses {
    padding: 6rem 0;
    position: relative;
    background-color: var(--bs-White);

    &__intro {
        margin-bottom: 4.938rem;

        &__title {
            font-size: 2.5rem;
            font-weight: 600;
            text-transform: uppercase;
            color: var(--bs-DarkGrey);
            margin-bottom: 0.875rem;
        }

        &__description {
            font-size: 1.125rem;
            font-weight: 300;
            color: var(--bs-DarkGreyVariant);
        }
    }

    &__switch {
        div {
            padding-left: 3.125rem;
        }

        &__item {
            font-weight: 500;
            display: block;
            margin-bottom: 1.875rem;
            text-decoration: none;
            position: relative;
            transition: all .2s ease;
            cursor: pointer;
            font-size: 0.875rem;
            text-transform: uppercase;
            color: var(--bs-Black);
        }

        &__item {
            &.active::after {
                color: var(--bs-Pineapple);
                cursor: default;
                transition: all .2s ease;
                position: absolute;
                content: ' ';
                top: 40%;
                left: -3.125rem;
                width: 2.5rem;
                height: 0.188rem;
                background-color: var(--bs-Pineapple);
                display: block;
            }
        }
    }

    &__container {
        position: relative;

        &__details {
            width: 100%;
            max-width: 370px;
            height: 300px;
            padding: 60px 40px;
            margin-top: 207px;
            margin-left: -132px;
            background-color: #fff;
            z-index: 30;
            position: relative;

            &__item {
                p {
                    text-align: center;
                    font-size: 0.938rem;
                }

                &__info {
                    .location {
                    }

                    .actions {
                        a {
                            width: 150px;
                            font-size: 1rem;
                            line-height: 40px;
                            display: block;
                            text-align: center;
                            color: var(--bs-White);
                            background-color: var(--bs-CornFlower);
                            border-radius: 4px;
                            font-weight: 500;
                            text-decoration: none;

                            &:hover {
                                background-color: var(--bs-BlueBerry);
                            }
                        }
                    }
                }
            }
        }

        &__image {
            width: 100%;
            height: 362px;
            position: absolute;
            background-color: black;
            background-size: cover;
            top: -203px;
        }
    }
}
