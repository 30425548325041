.pageOnline {
    .page_content {
        &__Links {
            width: 100%;
            padding: 60px 0;

            &__contentApp {
                width: 100%;
                height: 500px;
                padding: 0 120px;
                background-color: var(--bs-BlueBerry);

                h2 {
                    margin-top: 30px;
                    margin-bottom: 10px;
                    font-size: 1.375rem;
                    font-weight: 600;
                    color: var(--bs-White);
                    text-transform: uppercase;
                    position: relative;
                    text-align: center;
                }

                p {
                    text-align: center;
                    font-weight: 300;
                    margin-bottom: 30px;
                }

                .btn-white {
                    width: 150px;
                    border: none;
                    border-radius: 40px;
                    background: #F7971E; /* fallback for old browsers */
                    background: -webkit-linear-gradient(to right, #FFD200, #F7971E); /* Chrome 10-25, Safari 5.1-6 */
                    background: linear-gradient(to right, #FFD200, #F7971E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                }

            }

            &__contentFaq {
                width: 100%;
                height: 500px;
                padding: 0 120px;

                h2 {
                    margin-top: 50px;
                    margin-bottom: 10px;
                    font-size: 1.375rem;
                    font-weight: 600;
                    color: var(--bs-White);
                    text-transform: uppercase;
                    position: relative;
                    text-align: center;
                }

                .btn-blue {
                    width: 140px;
                    border-radius: 40px;
                    border: none;
                    background: #396afc; /* fallback for old browsers */
                    background: -webkit-linear-gradient(to right, #2948ff, #396afc); /* Chrome 10-25, Safari 5.1-6 */
                    background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                }
            }

            &__content {
                width: 100%;
                height: 500px;
                padding: 0 120px;
                background-color: var(--bs-LightGrey);

                h2 {
                    margin-top: 20px;
                    font-size: 1.375rem;
                    font-weight: 600;
                    color: var(--bs-DarkGreyVariant);
                    text-transform: uppercase;
                    position: relative;
                }

                p {
                    font-size: 1rem;
                    font-weight: 300;
                    color: var(--bs-DarkGreyVariant);
                    margin-bottom: 20px;
                }

                a {
                    width: 150px;
                }

                .btn-blue {
                    border: none;
                    border-radius: 0;
                    background: #396afc; /* fallback for old browsers */
                    background: -webkit-linear-gradient(to right, #2948ff, #396afc); /* Chrome 10-25, Safari 5.1-6 */
                    background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                }

                .btn-green {
                    border-radius: 0;
                    border: none;
                    color: #fff;
                    background: #4CB8C4; /* fallback for old browsers */
                    background: -webkit-linear-gradient(to right, #3CD3AD, #4CB8C4); /* Chrome 10-25, Safari 5.1-6 */
                    background: linear-gradient(to right, #3CD3AD, #4CB8C4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                }
            }

            &__image {
                width: 100%;
                height: 500px;
                background-image: url(../images/pages/page-online-whatsapp.jpg);
                background-size: cover;
                background-position: center;
            }
        }

        &__downloadFiles {
            padding: 30px 0 120px 0;
            position: relative;
            background: rgb(255, 255, 255); /* Old browsers */
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(238, 238, 238, 1)), color-stop(100%, rgba(251, 251, 251, 1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* IE10+ */
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fbfbfb', GradientType=0); /* IE6-9 */

            .card-c-body {
                padding: 20px;

                h3 {
                    font-size: 1.25rem;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                .btn {
                    font-size: 0.75rem;
                    text-transform: uppercase;
                }

                h5{
                    text-transform: uppercase;
                    font-size: 1rem;
                    text-align: center;
                }

                span{
                    font-size: .85rem;
                    font-weight: 600;
                    color: var(--bs-BlueBerry);
                }
            }

            .nav-pills .nav-link {
                height: 3.125rem;
                border-radius: initial;
                background-color: var(--bs-LightGrey);
                color: var(--bs-BlueBerry);
                font-weight: 600;
            }

            .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
                color: var(--bs-Black);
                background-color: var(--bs-Canary);
                font-weight: 600;

                &:before,
                &:after {
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -10px;
                    border: 10px solid transparent;
                    border-top-color: var(--bs-Canary);
                }

                &:after {
                    left: 50%;
                    border: 0 solid transparent;
                    border-bottom-color: var(--bs-White);
                }
            }

            .tab-pane {
                p {
                    font-size: 1rem;
                    font-weight: 200;
                }
            }
        }
    }
}
