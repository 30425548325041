//---------------------------------------------
//
//  Mixin: Google Fonts
//
//    1. Nomes do Google para arquivos de fontes
//    2. Carregando uma única fonte
//    3. Carregando várias fontes
//
//---------------------------------------------

//---------------------------------------------
// 1. Nomes do Google para arquivos de fontes
//---------------------------------------------

$fontWeightNames: (
    100: "Thin",
    200: "Light",
    300: "Regular",
    400: "SemiBold",
    500: "Bold",
    600: "ExtraBold",
    700: "Black"
);

//---------------------------------------------
// 2. Carregando uma única fonte
//---------------------------------------------

@mixin googleFont($font) {
    $fontFamily: map-get($font, "name");
    $folderName: map-get($font, "folder");
    $fileName: map-get($font, "files");
    $weights: map-get($font, "weights");
    @each $weight in $weights {
        $weightName: map-get($fontWeightNames, $weight);
        @font-face {
            font-family: "#{$fontFamily}";
            font-weight: $weight;
            font-display: swap;
            src: url("../../fonts/#{$folderName}/#{$fileName}-#{$weightName}.eot"); /* IE 9 - 11 */
            src: url("../../fonts/#{$folderName}/#{$fileName}-#{$weightName}.eot?#iefix") format('embedded-opentype'), /* IE 9 - 11 */
            url("../../fonts/#{$folderName}/#{$fileName}-#{$weightName}.woff2") format('woff2'),
            url("../../fonts/#{$folderName}/#{$fileName}-#{$weightName}.woff") format('woff');
        }
    }
}

//---------------------------------------------
// 3. Carregando várias fontes
//---------------------------------------------

@mixin googleFonts($fonts) {
    @each $name, $font in $fonts {
        @include googleFont($font);
    }
}
