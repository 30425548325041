.home_section_about {
    width: 100%;
    padding: 0 0 6rem 0;
    position: relative;

    &__intro {
        width: 100%;
        padding: 6rem 0;
        position: relative;

        &__wrapper_title {
            position: relative;

            h2 {
                font-size: 2.188rem;
                font-weight: 700;
            }

            &__left {
                position: absolute;
                left: 0;
                bottom: 70px;
            }

            &__right {
                position: absolute;
                right: -0.5rem;
                top: 2rem;
            }
        }

        p {
            font-size: 1.125rem;
            line-height: 1.875rem;
            font-weight: 300;
            color: var(--bs-DarkGrey);

            span {
                font-weight: 500;
            }
        }

        a {
            width: 150px;
            line-height: 40px;
            font-size: 1rem;
            display: block;
            text-align: center;
            color: var(--bs-White);
            background-color: var(--bs-CornFlower);
            border-radius: 4px;
            font-weight: 500;
            text-decoration: none;
            transition: all 0.5s ease;

            &:hover {
                background-color: var(--bs-BlueBerry);
            }
        }
    }

    &__carousel_pages {
        &__cell {
            width: 60%;
            height: 411px;
            margin-right: 10px;
            background: var(--bs-LightGrey);
            counter-increment: carousel-page-cell;

            &__bg {
                width: 100%;
                height: 411px;
                position: relative;
                background: radial-gradient(ellipse at center, rgba(30, 55, 153, 1) 0%, rgba(12, 36, 97, 1) 100%);
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                    position: absolute;
                    bottom: 0;
                    right: 10px;
                }
            }

            .carousel-page-cell-content {
                padding: 0.938rem;

                span {
                    font-size: 1.125rem;
                    font-weight: 500;
                    color: var(--bs-DarkGrey);
                }

                h2 {
                    font-size: 1.875rem;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: var(--bs-DarkGrey);
                }

                p {
                    font-size: 0.875rem;
                    font-weight: 300;
                    color: var(--bs-DarkGrey);

                    span {
                        font-size: 0.875rem;
                        font-weight: 500;
                    }
                }

                a {
                    width: 150px;
                    line-height: 40px;
                    font-size: 1rem;
                    display: block;
                    text-align: center;
                    color: var(--bs-Black);
                    background-color: var(--bs-Pineapple);
                    border-radius: 4px;
                    font-weight: 500;
                    text-decoration: none;

                    &:hover {
                        background-color: var(--bs-Canary);
                    }
                }
            }
        }

        .flickity-page-dots {
            bottom: -40px;
        }

        .flickity-page-dots .dot {
            width: 12px;
            height: 12px;
            border-radius: 12px;
            opacity: 1;
            background: var(--bs-Pineapple);
        }

        .flickity-page-dots .dot.is-selected {
            border: 2px solid var(--bs-Pineapple);
            background: transparent;
            width: 16px;
            height: 16px;
        }
    }
}
