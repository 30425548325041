.pageCourses {
    .page_content {

        .c_category {
            width: 120px;
            height: 25px;
            background-color: var(--bs-BlueBerry);
            text-align: center;
            color: var(--bs-White);
            display: block;
            border-radius: 25px;
            margin-bottom: 10px;
        }

        a {
            color: var(--bs-Black);
            text-decoration: none;
        }

        h5 {
            font-size: 1.063rem;
            font-weight: 600;
            margin-bottom: 10px;
        }

        p {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }

        .c_price {
            display: table;
            margin: 0 auto;
            font-size: 1.125rem;
            font-weight: 700;
        }

        small {
            font-size: 0.875rem;
            display: table;
            margin: 0 auto;
            color: var(--bs-DarkGrey);
            font-weight: 400;

            span {
                font-weight: 700;
                color: var(--bs-BlueBerry);
            }
        }

        &__title_wrapper {
            h2 {
                text-align: left;
                font-size: 2.188rem;
                margin-bottom: 0.625rem;

                &::after {
                    border-bottom: initial;
                }
            }

            img {
                left: initial;
            }
        }

        p {
            font-size: 0.938rem;
            line-height: 1.875rem;
            font-weight: 400;
            color: var(--bs-DarkGreyExtra);
        }

        &__courses_info {
            width: 80%;
            height: 150px;
            margin: 1.875rem 0;
            border-left: 1px dotted var(--bs-Black);
            border-bottom: 1px dotted var(--bs-Black);
            border-bottom-left-radius: 16px 16px;
            position: relative;

            img {
                margin-bottom: 5px;
            }

            small {
                font-size: 0.938rem;
                font-weight: 600;
                color: var(--bs-DarkGrey);
            }

            span {
                font-size: 0.875rem;
                font-weight: 700;
                color: var(--bs-Black);
            }

            &::after {
                position: absolute;
                top: -0.625rem;
                left: -0.313rem;
                content: ' ■ ';
            }

            &::before {
                position: absolute;
                right: -1.313rem;
                bottom: -1.66rem;
                content: ' ▸ ';
                font-size: 2rem;
            }

            &__itemUp {
                margin-top: -1.875rem;
                display: flex;
                flex-flow: wrap;
                align-items: center;
                justify-content: center;

                div {
                    flex: 1 100%;
                }
            }

            &__item {
                display: flex;
                flex-flow: wrap;
                align-items: center;
                justify-content: center;

                div {
                    flex: 1 100%;
                }
            }
        }

        &__imgIntro {
            border-radius: 12px;
        }

        &__mainCourses {
            width: 100%;
            padding: 120px 0 180px 0;
            background-color: var(--bs-LightGreyVariant100);
            position: relative;

            .course-divider-top {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                overflow: hidden;
                line-height: 0;
                transform: rotate(180deg);
            }

            .course-divider-top svg {
                position: relative;
                display: block;
                width: calc(100% + 1.3px);
                height: 147px;
            }

            .course-divider-top .shape-fill {
                fill: var(--bs-White);
            }

            .course-divider-bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                overflow: hidden;
                line-height: 0;
                transform: rotate(180deg);
            }

            .course-divider-bottom svg {
                position: relative;
                display: block;
                width: calc(290% + 1.3px);
                height: 229px;
            }

            .course-divider-bottom .shape-fill {
                fill: #FFFFFF;
            }


            &__search {
                position: relative;
                width: 100%;
                max-width: 600px;
                margin-top: -50px;

                input {
                    width: 100%;
                    padding: 0.800rem 2rem;
                    font-size: 1rem;
                    font-weight: 400;
                    box-shadow: 0 -3px 3px -3px rgba(0, 0, 0, 0.2);
                    border: none;
                }

                button {
                    width: 125px;
                    position: absolute;
                    right: 8px;
                    top: 30px;
                    border: 0;
                    background: var(--bs-Canary);
                    color: var(--bs-White);
                    outline: none;
                    padding: 0.450rem 2rem;
                    border-radius: 100px;
                    z-index: 2;
                }
            }

            &__list {
                width: 100%;
                padding: 60px 0;

                &__wrapper_title {
                    position: relative;
                    display: block;

                    h3 {
                        font-size: 1.875rem;
                        line-height: 1.875rem;
                        font-weight: 700;
                        letter-spacing: .13rem;
                    }

                    .imgShapeJungle {
                        top: 25px;
                        right: 5px;
                    }
                }

                .wt_desc {
                    font-size: 1.125rem;
                    font-weight: 300;
                }

                .carousel-cell {
                    width: 40%;
                    height: 460px;
                    margin-right: 15px;
                    border-radius: 5px;
                }
            }
        }

        &__moreCourses {
            width: 100%;
            padding: 0 0 60px 0;
        }

    }
}

