.horizontal-timeline {
    opacity: 0;
    width: 100%;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}
.horizontal-timeline ol {
    margin: 0;
    padding: 0;
    width: 100%;
}
/* Never visible - this is used in jQuery to check the current MQ */
.horizontal-timeline::before {
    content: 'desktop';
    display: none;
}
.horizontal-timeline.loaded {
    opacity: 1;
    margin: 0 !important;
}
.horizontal-timeline .touch-enabled {
    cursor: grab;
}
/* Timeline */
.horizontal-timeline .timeline {
    position: relative;
    height: auto;
    margin: 0 auto;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.horizontal-timeline .events-wrapper {
    position: relative;
    /* Fixed up a bug where if the date display was split into two lines
    half of the top was cut off (Changed the height to accommodate) */
    height: 80px;
    overflow: hidden;
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important;
}
.horizontal-timeline .events {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 13%;
    height: 5px;
    /* width will be set using JavaScript */
    background: #000;
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
}
/* Filling line */
.horizontal-timeline .filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #002bff;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}
/* Event dates on the timeline */
.horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    text-decoration: none;
    padding-bottom: 15px;
    color: #000;
    /* Fix bug on Safari - text flickering while timeline translates */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    user-select: none;
}
/* Dots for the event dates on the timeline */
.horizontal-timeline .events a::after {
    content: '';
    position: absolute;
    left: 40%;
    bottom: -3px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #000;
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
}
/* Stop mouse pointer events on a selected event */
.horizontal-timeline .events a.selected {
    pointer-events: none;
}
.horizontal-timeline .events a.selected::after,
.horizontal-timeline .events a.older-event::after {
    background-color: #002bff;
}
.horizontal-timeline .events a:hover {
    text-decoration: underline;
}
/* Navigation */
.timeline-navigation {
    position: relative;
    top: 3px;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
    z-index: 2;
}
.timeline-navigation#leftNav {
    left: 1px;
}
.timeline-navigation#rightNav {
    right: 1px;
}
.timeline-navigation a {
    display: inline;
    text-decoration: none;
    color: #000;
}
.timeline-navigation a:hover {
    color: #002bff;
    border-color: #002bff;
}

/* Inactive */
.timeline-navigation a.inactive {
    cursor: not-allowed;
    border-color: #d3d3d3;
    color: #d3d3d3;
}
/* Autoplay Pause/Play button */
.timeline-navigation#pausePlay {
    width: 100%;
    top: -10px;
    text-align: center;
}
/* Event content */
.horizontal-timeline .events-content {
    position: relative;
    margin: 2em 0;
    margin-left: auto;
    margin-right: auto;
    -webkit-transition: height 0.4s 0.2s;
    -moz-transition: height 0.4s 0.2s;
    transition: height 0.4s 0.2s;
}
/* Autoplay progress bar */
.horizontal-timeline .events-content .progressBarWrapper {
    position: absolute;
    width: 100%;
    height: 4px;
    margin-top: -4px;
    padding: 0 20px;
}
.horizontal-timeline .events-content .progressBar {
    width: 0%;
    max-width: 100%;
    height: inherit;
    background: #002bff;
}
.horizontal-timeline .events-content li[data-horizontal-timeline],
    /* data-date deprecated as of v2.0.5.alpha.3 */
.horizontal-timeline .events-content li[data-date] {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    margin: auto;
    padding: 10px;
    background: #ccc;
    border-radius: 20px;
    opacity: 0;

    font-size: 16pt;
    text-align: center;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    transition: opacity 1s;
}
/* The selected events content */
.horizontal-timeline .events-content li[data-horizontal-timeline].selected,
    /* data-date deprecated as of v2.0.5.alpha.3 */
.horizontal-timeline .events-content li[data-date].selected {
    position: relative;
    z-index: 2;
    opacity: 1;
}
/* Event content animations */
.horizontal-timeline .events-content li.enter-right,
.horizontal-timeline .events-content li.exit-right {
    -webkit-animation-name: timeline-enter-right;
    -moz-animation-name: timeline-enter-right;
    animation-name: timeline-enter-right;
}
.horizontal-timeline .events-content li.enter-left,
.horizontal-timeline .events-content li.exit-left {
    -webkit-animation-name: timeline-enter-left;
    -moz-animation-name: timeline-enter-left;
    animation-name: timeline-enter-left;
}
.horizontal-timeline .events-content li.exit-right,
.horizontal-timeline .events-content li.exit-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse;
}
/* Speed */
.horizontal-timeline .events-content li.animationSpeed {
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
}
@-webkit-keyframes timeline-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}
@-moz-keyframes timeline-enter-right {
    0% {
        opacity: 0;
        -moz-transform: translateX(100%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}
@keyframes timeline-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}
@-webkit-keyframes timeline-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}
@-moz-keyframes timeline-enter-left {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}
@keyframes timeline-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}
@media only screen and (min-width: 1100px) {
    .horizontal-timeline {
        margin: 6em auto;
    }
}
@media only screen and (min-width: 501px) and (max-width: 1100px) {
    /* Never visible - this is used in jQuery to check the current MQ */
    .horizontal-timeline::before {
        content: 'tablet';
    }
}
@media only screen and (max-width: 500px) {
    /* Never visible - this is used in jQuery to check the current MQ */
    .horizontal-timeline::before {
        content: 'mobile';
    }
}
@media only screen and (max-width: 1100px) {
    .horizontal-timeline .timeline {
        padding-bottom: 40px !important;
    }
    .timeline-navigation#leftNav {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .timeline-navigation#rightNav {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .timeline-navigation#leftNav,
    .timeline-navigation#rightNav {
        display: -ms-flexbox !important;
        display: flex !important;
        top: -5px !important;
    }
    .timeline-navigation#pausePlay {
        top: 20px !important;
    }
}
