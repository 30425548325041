#navbar {
  transition: 0.4s;
  z-index: 99;
}

.navbar {
  //border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  .objetivo-horizontal-obj:before {
    content: "\e900";
    color: var(--bs-BlueBerry);
    font-size: 3rem;
  }

  .navbar-toggler {
    border: none;
    cursor: pointer;
  }

  .navbar-brand {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: initial;
    margin-top: 30px;
  }

  li {
    padding: 0 0.3rem;

    .nav-link {
      padding: 0 0.3rem;
      font-weight: 500;
      font-size: 1.087rem;
      color: var(--bs-GreyLong) !important;
    }
  }

  .dropdown-menu {
    margin-top: 36px;
    border: none;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 0.813rem;
    padding: 0;
    min-width: 15rem;

    li {
      padding: 0;

      .dropdown-item {
        padding: 1rem 1.25rem;
        color: var(--bs-Black);
        font-weight: 500;
        transition: all 200ms linear;

        &:hover {
          background-color: var(--bs-CornFlower);
          color: var(--bs-White);
        }
      }
    }
  }

  .btn_header_blue {
    border: none;
    background: #396afc; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2948ff, #396afc); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #2948ff,
      #396afc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  .btn_header_yellow {
    border: none;
    color: #fff;
    background: #4cb8c4; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3cd3ad, #4cb8c4); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #3cd3ad,
      #4cb8c4
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}

.nav-colored {
  background-color: #fff !important;
  border: none;
  //box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);

  li {
    .nav-link {
      font-weight: 500;
      color: var(--bs-DarkGrey) !important;
    }
  }

  .objetivo-horizontal-obj:before {
    content: "\e900";
    color: var(--bs-BlueBerry);
  }
}
