@media (min-width: 280px) and (max-width: 510px) {
    .home_section_about {
        &__carousel_pages {
            &__cell {
                width: 100% !important;
                height: 300px !important;
                background: var(--bs-LightGrey);
                counter-increment: carousel-page-cell;

                &__bg {
                    height: 300px !important;

                    img {
                        max-width: 100% !important;
                        height: auto !important;
                    }
                }
            }

            .carousel-page-cell-content {

                span {
                    font-size: 0.85rem !important;
                }

                h2 {
                    font-size: 1rem !important;
                }

                a {
                    line-height: 30px !important;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .home_section_about {
        &__intro {
            &__wrapper_title {
                h2 {
                    font-size: 2rem;
                }

                &__left {
                    position: absolute;
                    left: 0;
                    bottom: 120px;
                }

                &__right {
                    position: absolute;
                    right: -0.5rem;
                    top: 6rem;
                }
            }
        }
    }
}
