@media (min-width: 280px) and (max-width: 768px) {
    .pageOnline {
        .page_content {
            .page_content__title_wrapper {
                h2 {
                    text-align: center;
                    margin-bottom: 30px;

                    &::after {
                        left: 50%;
                        margin-left: -37.5px;
                    }
                }
            }

            &__Links {
                &__contentApp {
                    padding: 0 20px;
                    background-size: cover;
                }

                &__contentFaq {
                    padding: 0 20px;
                }

                &__content {
                    width: 100%;
                    height: 300px;
                    padding: 0 20px;
                    text-align: center;

                    .btn-green {
                        border-radius: 0;
                        border: none;
                        color: #fff;
                        background: #4CB8C4; /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #3CD3AD, #4CB8C4); /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #3CD3AD, #4CB8C4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                    }
                }

                &__image {
                    width: 100%;
                    height: 300px;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
}
