.main_footer {
    position: relative;
    z-index: 11;
    &__content {
        width: 100%;
        background-color: var(--bs-Denim);

        &__newsletter {
            width: 100%;
            height: 480px;
            margin-top: -3.75rem;
            background-color: var(--bs-CornFlower);

            &__intro {
                padding: 0 30px;

                h2 {
                    font-size: 1.375rem;
                    color: var(--bs-White);
                    font-weight: 600;
                }

                p {
                    font-size: 0.938rem;
                    color: var(--bs-White);
                    margin-bottom: 50px;
                    font-weight: 300;
                }

                &__form {
                    label {
                        font-size: 1rem;
                        color: rgba(255, 255, 255, 0.5);
                        margin-left: 15px;
                        font-weight: 300;
                    }

                    .form-control {
                        background: transparent;
                        border: none;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                        border-radius: 0;
                        color: rgba(255, 255, 255, 0.8);
                    }

                    button {
                        width: 200px;
                        height: 50px;
                        border-radius: 50px;
                        margin-top: 30px;
                        border: none;
                        background-color: var(--bs-Canary);
                        text-transform: uppercase;
                        font-size: 1rem;
                        font-weight: 600;
                        color: var(--bs-Black);
                    }
                }
            }
        }

        &__footer_logo {
            width: 100%;
            margin: 25px 0;
            a{
                text-decoration: none;
                .objetivo-vertical-obj:before {
                    content: "\e900";
                    color: rgba(255,255,255,.5);
                    font-size: 6rem;
                }
            }
        }

        &__footer_social {
            width: 100%;
            margin-bottom: 50px;
            a{
                width: 50px;
                height: 50px;
                line-height: 40px;
                border-radius: 50px;
                margin-right: 5px;
                margin-left: 5px;
                border: 3px solid rgba(255,255,255,.5);
                color: rgba(255,255,255,.5);
                text-align: center;
                display: inline-block;
                transition: all .5s;
                &:hover{
                    background-color: var(--bs-Pineapple);
                    color: var(--bs-White);
                    border: 3px solid var(--bs-Pineapple);
                }
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0 0 20px 0;
            border-bottom: 1px solid rgba(255, 255, 255, .5);

            li {
                a {
                    font-size: 14px;
                    margin-left: 10px;
                    margin-right: 10px;
                    color: var(--bs-White);
                    text-decoration: none;
                    font-weight: 600;
                }
            }
        }

        &__footer_campuses {
            color: var(--bs-White);

            .row {
                margin-top: 20px;
            }

            h5 {
                font-size: 14px;
                font-weight: 500;
            }

            p {
                font-size: 13px;
                font-weight: 200;
            }
        }
    }

    &__rights {
        width: 100%;
        height: 50px;
        background-color: var(--bs-BlueBerry);

        p {
            color: var(--bs-White);
            font-weight: 500;
        }
    }
}
