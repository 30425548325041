.pageContact {
    .page_content {
        .inner-box {
            width: 100%;
            //height: 300px;
            justify-content: center;
            align-items: center;
            display: flex;
            padding: 58px 30px 54px 30px;
            border-radius: 10px;
            text-align: center;
            position: relative;
            overflow: hidden;
            background: #fdfdfd;
            box-shadow: 0 0 12px rgba(0, 0, 0, .1);

            .icon-box {
                margin-bottom: 20px;
            }

            h3 {
                font-size: 1.3rem;
                font-weight: 600;
            }

            p {
                font-size: 1rem;
                font-weight: 300;
                color: var(--bs-DarkGrey);

                a {
                    font-weight: 400;
                    text-decoration: none;
                    color: var(--bs-BlueBerry);
                }
            }

            &::before {
                content: "";
                display: block;
                width: 80px;
                height: 80px;
                background: #f6f6f6;
                position: absolute;
                left: -40px;
                top: -30px;
                border-radius: 50%;
            }


            &::after {
                content: "";
                display: block;
                width: 80px;
                height: 80px;
                background: var(--bs-LightGrey);
                position: absolute;
                left: auto;
                bottom: -40px;
                top: auto;
                right: -40px;
                border-radius: 50%;
            }
        }

        &__form {
            width: 100%;
            padding: 60px 0;

            form {
                margin-top: 30px;
            }

            h3 {
                font-size: 3rem;
                font-weight: 600;
            }

            input {
                outline: none;
                border: none;
            }

            textarea {
                outline: none;
                border: none;
            }

            textarea:focus, input:focus {
                border-color: transparent !important;
            }

            input:focus::-webkit-input-placeholder {
                color: transparent;
            }

            input:focus:-moz-placeholder {
                color: transparent;
            }

            input:focus::-moz-placeholder {
                color: transparent;
            }

            input:focus:-ms-input-placeholder {
                color: transparent;
            }

            textarea:focus::-webkit-input-placeholder {
                color: transparent;
            }

            textarea:focus:-moz-placeholder {
                color: transparent;
            }

            textarea:focus::-moz-placeholder {
                color: transparent;
            }

            textarea:focus:-ms-input-placeholder {
                color: transparent;
            }

            input::-webkit-input-placeholder {
                color: #adadad;
            }

            input:-moz-placeholder {
                color: #adadad;
            }

            input::-moz-placeholder {
                color: #adadad;
            }

            input:-ms-input-placeholder {
                color: #adadad;
            }

            textarea::-webkit-input-placeholder {
                color: #adadad;
            }

            textarea:-moz-placeholder {
                color: #adadad;
            }

            textarea::-moz-placeholder {
                color: #adadad;
            }

            textarea:-ms-input-placeholder {
                color: #adadad;
            }

            .wrap-input100 {
                width: 100%;
                position: relative;
                border-bottom: 2px solid #d9d9d9;
                padding-bottom: 13px;
                margin-bottom: 27px;
            }

            .label-input100 {
                font-size: 13px;
                color: #666666;
                line-height: 1.5;
                padding-left: 5px;
            }

            .input100 {
                display: block;
                width: 100%;
                background: transparent;
                font-size: 18px;
                color: #333333;
                line-height: 1.2;
                padding: 0 5px;
            }

            .focus-input100 {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                pointer-events: none;
            }


            .focus-input100::before {
                content: "";
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 0;
                height: 2px;

                -webkit-transition: all 0.4s;
                -o-transition: all 0.4s;
                -moz-transition: all 0.4s;
                transition: all 0.4s;

                background: #7f7f7f;
            }

            input.input100 {
                height: 40px;
            }


            textarea.input100 {
                min-height: 110px;
                padding-top: 9px;
                padding-bottom: 13px;
            }

            .input100:focus + .focus-input100::before {
                width: 100%;
            }

            .has-val.input100 + .focus-input100::before {
                width: 100%;
            }

            .select2-container {
                display: block;
                max-width: 100% !important;
                width: auto !important;
            }

            .select2-container .select2-selection--single {
                display: -webkit-box;
                display: -webkit-flex;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                background-color: transparent;
                border: none;
                height: 40px;
                outline: none;
                position: relative;
            }

            /*------------------------------------------------------------------
            [ in select ]*/
            .select2-container .select2-selection--single .select2-selection__rendered {
                font-size: 18px;
                color: #333333;
                line-height: 1.2;
                padding-left: 5px;
                background-color: transparent;
            }

            .select2-container--default .select2-selection--single .select2-selection__arrow {
                height: 100%;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                display: -webkit-box;
                display: -webkit-flex;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .select2-selection__arrow b {
                display: none;
            }

            .select2-selection__arrow::before {
                content: '';
                display: block;

                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                border-bottom: 5px solid #999999;
                margin-bottom: 2px;
            }

            .select2-selection__arrow::after {
                content: '';
                display: block;

                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                border-top: 5px solid #999999;
            }

            /*------------------------------------------------------------------
            [ Dropdown option ]*/
            .select2-container--open .select2-dropdown {
                z-index: 1251;
                border: 0px solid #e5e5e5;
                border-radius: 0px;
                background-color: white;

                box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.2);
                -webkit-box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.2);
                -o-box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.2);
                -ms-box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.2);
            }

            .select2-dropdown--above {
                top: -30px;
            }

            .select2-dropdown--below {
                top: 8px;
            }

            .select2-container .select2-results__option[aria-selected] {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .select2-container .select2-results__option[aria-selected="true"] {
                background: var(--bs-BlueBerry);
                background: -webkit-linear-gradient(right, $BlueBerry, $CornFlower);
                background: -o-linear-gradient(right, #00dbde, #fc00ff);
                background: -moz-linear-gradient(right, #00dbde, #fc00ff);
                background: linear-gradient(right, $BlueBerry, $CornFlower);
                color: white;
            }

            .select2-container .select2-results__option--highlighted[aria-selected] {
                background: #a64bf4;
                background: -webkit-linear-gradient(right, $BlueBerry, $CornFlower);
                background: -o-linear-gradient(right, #00dbde, #fc00ff);
                background: -moz-linear-gradient(right, #00dbde, #fc00ff);
                background: linear-gradient(right, $BlueBerry, $CornFlower);
                color: white;
            }

            .select2-results__options {
                font-size: 15px;
                color: #333333;
                line-height: 1.2;
            }

            .select2-search--dropdown .select2-search__field {
                border: 1px solid #aaa;
                outline: none;
                font-size: 15px;
                color: #333333;
                line-height: 1.2;
            }

        }
    }
}
