@media (min-width: 280px) and (max-width: 510px) {
  .carousel-indicators [data-bs-target] {
    display: none;
  }
  .carousel-item {
    .container {
      h2 {
        font-size: 2.5rem !important;
        text-align: center !important;
      }

      a {
        text-align: center !important;
        display: table;
        margin: 0 auto;
      }
    }
  }
  #homeSectionHero {
    .flickity-prev-next-button {
      width: 35px;
      height: 35px;
      border-radius: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .carousel-indicators [data-bs-target] {
    font-size: 1rem;
  }

  .carousel-indicators {
    padding: 0 30px;
  }

  .carousel-item {
    img {
    }

    video {
    }

    .container-carousel {
    }
  }
}
