.home_section_blog {
    padding: 6rem 0 8rem 0;
    position: relative;

    &__header {
        width: 100%;
        height: 330px;

        &__titles {
            display: table;
            margin: 60px auto;
            color: var(--bs-White);

            span {
                margin-bottom: 30px;
                font-size: 1.125rem;
                font-weight: 500;
            }

            h2 {
                font-size: 1.875rem;
                font-weight: 600;
            }
        }
    }

    &__content {
        margin-top: -100px;

        .card-title {
            font-size: 1rem;
            font-weight: 700;
        }

        .card-text {
            font-size: 0.875rem;
        }

        a {
            text-decoration: none;
            color: var(--bs-Black);
            transition: ease all .600s;

            &:hover {
                color: var(--bs-BlueBerry);
            }
        }

        .r_more {
            font-weight: 600;
            text-decoration: none;
            color: var(--bs-DarkGrey);

            span {
                margin-right: 5px;
            }
        }
    }
}
