#homeSectionHero {
  .hero-cell {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .flickity-page-dots {
    bottom: 30px;
  }
  .flickity-page-dots .dot {
    width: 15px;
    height: 15px;
    opacity: 1;
    background: var(--bs-Pineapple);
  }

  /* fill-in selected dot */
  .flickity-page-dots .dot.is-selected {
    border: 3px solid var(--bs-Pineapple);
    background: transparent;
    width: 20px;
    height: 20px;
  }

  .flickity-prev-next-button {
    width: 70px;
    height: 70px;
    border-radius: 100%;
  }

  .flickity-prev-next-button.previous {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .flickity-prev-next-button.next {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
