.pageCourse {
    .page_content {
        background-color: var(--bs-LightGreyVariant100);

        &__super_title {
            font-size: 2.375rem;
            position: relative;
            padding: 10px 20px;
            font-weight: 600;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 15%;
                height: 30px;
                border-left: 10px solid var(--bs-Pineapple);
            }
        }

        &__contentCourse {
            width: 100%;
            padding: 60px 0;

            &__courseCheckout {
                width: 100%;
                padding: 12px;
                background-color: var(--bs-White);
                border-radius: 4px;

                img {
                    height: 240px;
                    object-fit: cover;
                }

                p {
                    font-size: 0.875rem;
                    color: var(--bs-DarkGrey);
                    font-weight: 600;
                }

                a {
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    background-color: #2CC66D;
                    transition: ease all .600s;
                    color: var(--bs-White);
                    text-transform: uppercase;
                    font-weight: 600;
                    text-align: center;
                    display: block;
                    text-decoration: none;
                    border-radius: 4px;

                    &:hover {
                        background-color: var(--bs-Jungle);
                    }
                }
            }

            .sticky-sidebar {
                top: 7rem;
            }

            .nav-pills {
                a {
                    font-weight: 600;
                }
            }

            .tab-content {
                p {
                    font-size: 1rem;
                    color: var(--bs-DarkGreyExtra);
                    font-weight: 300;
                }

                h2 {
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin-bottom: 30px;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        p {
                            font-size: 1rem;
                            font-weight: 300 !important;
                            margin-bottom: 0;
                            color: var(--bs-DarkGreyExtra);
                        }

                        margin-bottom: 20px;
                    }
                }

                .listSvg {
                    fill: var(--bs-Canary);
                    margin-right: 7px;
                }
            }

            .accordion {

                h2 {
                    margin-bottom: 0;
                }

                .accordion-item {
                    border-right: 0;
                    border-left: 0;
                }

                .courTitleFaq {
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin-bottom: 30px;
                }

                .accordion-button {
                    color: var(--bs-DarkGrey);
                    font-weight: 300;

                }

                .accordion-button:not(.collapsed) {
                    background-color: var(--bs-BlueBerry);
                    color: var(--bs-White);
                }
            }
        }

        &__teachers {
            width: 100%;
            background-color: var(--bs-White);
            padding: 30px 0 60px 0;

            h2 {
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 30px;
            }

            .card-body {
                .card-text {
                    font-size: 0.875rem;
                    font-weight: 300;
                }
            }

            .card-footer {
                h5 {
                    font-size: 1.375rem;
                    color: var(--bs-BlueBerry);
                    font-weight: 600;
                }

                span {
                    font-size: 1.125rem;
                    color: #888;
                    font-weight: 300;
                }
            }
        }
    }
}
