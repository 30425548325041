//---------------------------------------------
//
//  Índice
//
//    1. Reset
//    2. Fundamentos
//    3. Layout da página
//    4. Componentes
//    5. Breakpoints
//
//---------------------------------------------

//---------------------------------------------
// 1. Reset
//---------------------------------------------
@import "reset";

//---------------------------------------------
// 2. Fundamentos
//---------------------------------------------
@import "variables";
@import "fonts";
@import "../plugins/bootstrap/bootstrap";
@import "../plugins/flickity/flickity";
@import "../plugins/timeline/timeline";
@import "../plugins/select2/select2.min.css";
@import "../plugins/owl-Carousel/owl.carousel.min.css";
@import "../plugins/owl-Carousel/owl.theme.default.min.css";
@import "~@fancyapps/ui/dist/fancybox.css";

//---------------------------------------------
// 3. Layout da página
//---------------------------------------------
@import "layouts/home/home_section_about";
@import "layouts/home/home_section_campuses";
@import "layouts/home/home_section_hero";
@import "layouts/home/home_section_testimonials";
@import "layouts/home/home_section_blog";

//---------------------------------------------
// 4.Componentes
//---------------------------------------------
@import "components/navbar";
@import "components/footer";

//---------------------------------------------
// 4.Pages
//--------------------------------------------
@import "layouts/pages/pages";

//---------------------------------------------
// 5. Breakpoints
//---------------------------------------------
@import "responsive/components/navbar";
@import "responsive/home/home_section_hero";
@import "responsive/home/home_section_about";
@import "responsive/home/home_section_campuses";
@import "responsive/home/home_section_testimonials";
@import "responsive/pages/online";
@import "responsive/pages/about";
@import "responsive/pages/segments";
@import "responsive/pages/post";

.btn-play {
  position: absolute;
  z-index: 10;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: var(--bs-Canary);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: var(--bs-Canary);
    border-radius: 50%;
    transition: all 200ms;
  }

  &:hover::after {
    background-color: darken($Canary, 10%);
  }

  span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #fff;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
  }
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
}
