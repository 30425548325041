.pageHero {
    width: 100%;
    height: 80vh;
    position: relative;

    img {
        object-fit: cover;
        width: 100%;
        min-width: 100%;
        height: 80vh;
        position: absolute;
        top: 0;
        left: 0;
    }

    video {
        object-fit: cover;
        width: 100vw;
        height: 80vh;
        position: absolute;
        top: 0;
        left: 0;
    }

    .pattern {
        width: 100%;
        height: 80vh;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(96, 96, 96, 0.5) !important;
        z-index: 11;
    }

    .container {
        position: relative;
        z-index: 12;
        overflow: hidden;
        color: var(--bs-White);

        h2 {
            font-size: 3.75rem;
            font-weight: 700;
            text-transform: uppercase;
            position: relative;
            border-left: 2px solid var(--bs-Pineapple);
            padding: 20px;
        }

        .page_pattern {
            font-size: 1.25rem;
            font-weight: 700;
            color: var(--bs-White);
            position: relative;
            margin-top: 1.875rem;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: -0.625rem;
                height: 1px;
                width: 35px;
                border-bottom: 2px solid var(--bs-Pineapple);
            }
        }
    }
}

.navbar-page {
    width: 100%;
    height: 70px;
    background-color: var(--bs-BlueBerry);

    ol {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .breadcrumb-item {
        text-transform: uppercase;
        font-size: 0.875rem;

        a {
            color: var(--bs-White);
            transition: all ease .600ms;
            text-decoration: none;

            &:hover {
                color: var(--bs-Pineapple);
            }
        }
    }

    .breadcrumb-item.active {
        color: var(--bs-Pineapple) !important;
    }
}

.t_ri {
    .blob-wrapper {
        max-width: 400px;
        height: 400px;
        margin: 0 auto;
    }
    .blob {
        width: 100%;
        height: 100%;
        margin-top: 20px;
        background-size: cover !important;
        border-radius: 33% 67% 45% 55% / 46% 44% 56% 54%;
    }
    .blogB {
        width: 100%;
        height: auto;
        position: relative;

        img {
            width: 80%;
            left: 0;
            bottom: 0;
        }

        .blobContainer {
            width: 100%;
            height: 550px;
            background-size: cover;
            overflow: hidden;
            background-image: url(../images/blob.png);
        }

        .play {
            width: 100px;
            height: 100px;
            background-color: var(--bs-Pineapple);
            border-radius: 100px;
            position: absolute;
            right: 50px;
            bottom: 100px;
            color: var(--bs-White);
        }
    }

    p {
        font-size: 1.40rem;
        font-weight: 300;
        position: relative;

        img {
            position: absolute;
            right: 50px;
        }
    }

    h2 {
        font-size: 1.25rem;
        line-height: 1rem;
        margin-bottom: 0;
        font-weight: 700;
    }

    .proposal_pre {
        width: 100%;
        padding: 60px 0 120px 0;

        .carousel {
            width: 100%;

            .carousel-cell {
                width: 20%;
                margin-right: 10px;
                border-radius: 5px;
                counter-increment: carousel-cell;

                .card-header {
                    width: 100%;
                    height: 200px;
                    position: relative;
                    background: radial-gradient(ellipse at center, rgba(30, 55, 153, 1) 0%, rgba(12, 36, 97, 1) 100%);
                    overflow: hidden;

                    img {
                        position: absolute;
                        bottom: 0;
                    }
                }

                .card-text {
                    font-size: 0.875rem;
                }

                .card-title {
                    font-size: 1.125rem;
                    margin-bottom: 0;
                    font-weight: 700;
                }

                small {
                    font-size: 0.875rem;
                    color: var(--bs-GreyLong);
                }
            }
        }

    }
}

#customers-testimonials .image-testimonial {
    width: 100%;
    height: 200px;
    position: relative;
    background: #396afc; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2948ff, #396afc); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#customers-testimonials .image-testimonial img {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -75px;
}

#customers-testimonials .item {
    margin-bottom: 20px;
    opacity: 0.2;
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#customers-testimonials .owl-item.active.center .item {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

.owl-carousel .owl-item img {
    max-width: 150px;
}

#customers-testimonials p {
    font-size: 0.875rem;
}

#customers-testimonials .card-footer {
    background: var(--bs-White);
    border: none;
}

#customers-testimonials .card-footer h5 {
    background: var(--bs-White);
    margin-top: 1.125rem;
    font-size: 1.125rem;
    line-height: 0;
    font-weight: 700;
}


#customers-testimonials .card-footer span{
    color: var(--bs-DarkGreyExtra);
    font-weight: 300;
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
    background-color: transparent;
    border: 3px solid var(--bs-Canary);
    border-radius: 100%;
    transform: translate3d(0px, -50%, 0px) scale(0.7);
}

#customers-testimonials.owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot {
    display: inline-block;
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
    background: var(--bs-Canary);
    border-radius: 100%;
    display: inline-block;
    height: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
    width: 20px;
}

#courses-intro .image-testimonial {
    width: 100%;
    height: 200px;
    position: relative;
    background: #396afc; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2948ff, #396afc); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#courses-intro .image-testimonial img {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -75px;
}

#courses-intro .item {
    margin-bottom: 20px;
    opacity: 0.2;
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#courses-intro .owl-item.active.center .item {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

#courses-intro.owl-carousel .owl-item img {
    max-width: 100%;
}

#courses-intro p {
    font-size: 0.875rem;
    line-height: 0.938rem;
}

#courses-intro .card-footer {
    background: var(--bs-White);
    border: none;
}

#courses-intro .card-footer h5 {
    background: var(--bs-White);
    margin-top: 1.125rem;
    font-size: 1.125rem;
    line-height: 0;
    font-weight: 700;
}


#courses-intro .card-footer span{
    color: var(--bs-DarkGreyExtra);
    font-weight: 300;
}

#courses-intro.owl-carousel .owl-dots .owl-dot.active span,
#courses-intro.owl-carousel .owl-dots .owl-dot:hover span {
    background-color: transparent;
    border: 3px solid var(--bs-Canary);
    border-radius: 100%;
    transform: translate3d(0px, -50%, 0px) scale(0.7);
}

#courses-intro.owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

#courses-intro.owl-carousel .owl-dots .owl-dot {
    display: inline-block;
}

#courses-intro.owl-carousel .owl-dots .owl-dot span {
    background: var(--bs-Canary);
    border-radius: 100%;
    display: inline-block;
    height: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
    width: 20px;
}

@import "about/about_history";
@import "about/about_mission_and_values";
@import "about/about_propostal";
@import "segments/segments";
@import "study_with_us";
@import "online";
@import "courses";
@import "course";
@import "contact";
@import "post";
@import "event";
