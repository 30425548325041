.page_content {

    &__inner {
        &__intro {
            p {
                font-size: 1rem;
                line-height: 2rem;
                margin-bottom: 1.75rem;
                color: var(--bs-DarkGrey);
                font-weight: 300;
            }
        }
    }

    &__subpages {
        width: 100%;
        padding: 60px 0 30px 0;

        img {
            width: 100%;
            height: 480px;
            object-fit: cover;
        }

        &__containerPag {
            width: 100%;
            height: 480px;
            background-color: var(--bs-BlueBerry);
            color: var(--bs-White);

            h2 {
                padding: 0 0.625rem;
                font-size: 1.75rem;
                text-transform: uppercase;
                border-left: 10px solid var(--bs-Pineapple);
                margin-bottom: 30px;
            }

            p {
                font-size: 0.875rem;
                font-weight: 200;
                margin-bottom: 1.75rem;
                padding: 0 20px;
            }
        }

        .page-bg-very-blue {
            background-color: #0C2461;
        }

        .set-scheme-yellow {
            h2 {
                border-left: 10px solid var(--bs-Canary);
            }

            a {
                background-color: var(--bs-Canary);
                border-color: var(--bs-Canary);
            }
        }

        &__containerPagS {
            width: 100%;
            height: 480px;
            color: var(--bs-Black);

            h2 {
                padding: 0 0.625rem;
                font-size: 1.75rem;
                text-transform: uppercase;
                border-left: 10px solid var(--bs-Jungle);
                margin-bottom: 30px;
                margin-top: 30px;
            }

            &__descIntro {
                font-size: 1.25rem;
                font-weight: 200;
                margin-bottom: 2.188rem;
                padding: 0 20px;
            }

            &__descIntroS {
                font-size: 0.938rem;
                font-weight: 300;
                margin-bottom: 2.188rem;
                padding: 0 20px;
            }

            a {
                width: 150px;
                background-color: var(--bs-Jungle);
                color: var(--bs-White);
                margin: 0 20px;

                &:hover {
                    color: var(--bs-White);
                }
            }
        }
    }

    &__differentials {
        width: 100%;
        padding: 30px 0 90px 0;
        position: relative;
        background: rgb(255, 255, 255); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(238, 238, 238, 1)), color-stop(100%, rgba(251, 251, 251, 1))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(238, 238, 238, 1) 100%, rgba(251, 251, 251, 1) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fbfbfb', GradientType=0); /* IE6-9 */

        &__content {
            margin-top: 30px;

            &__lfImg {
                width: 100%;
                height: 375px;
                background-color: var(--bs-BlueBerry);
                position: relative;

                img {
                    width: 100%;
                    min-width: 100%;
                    height: 375px;
                    object-fit: cover;
                }

                .pattern {
                    width: 100%;
                    height: 375px;
                    background: rgba(12, 36, 36, .7);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            &__rfImg {
                width: 100%;
                height: 375px;
                background-size: cover;
                background-position: center;
            }
        }

        .nav-pills .nav-link {
            height: 3.125rem;
            border-radius: initial;
            border-top: 1px solid var(--bs-GreyLong);
            border-bottom: 1px solid var(--bs-GreyLong);
            border-left: 1px solid var(--bs-GreyLong);
            border-right: 1px solid var(--bs-GreyLong);
            color: var(--bs-BlueBerry);
            font-weight: 600;
        }

        &__divider {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
            transform: rotate(180deg);
        }

        &__divider svg {
            position: relative;
            display: block;
            width: calc(100% + 1.3px);
            height: 110px;
            transform: rotateY(180deg);
        }

        &__divider .shape-fill {
            fill: #FFFFFF;
        }
    }

    &__super_titleIns {
        text-transform: uppercase;
        font-size: 1.563rem;
        position: relative;
        margin-left: 15px;
        font-weight: 700;

        &::after {
            content: '';
            position: absolute;
            left: -15px;
            top: 18%;
            height: 65%;
            border-left: 5px solid #F2C94C;
            border-left: 5px solid var(--bs-Pineapple);
        }
    }

    &__events {
        position: relative;

        &__intro {
            padding: 60px 0 30px 0;

            &__wrapper {
                position: relative;

                img {
                    position: absolute;
                    left: 105px;
                    top: -25px;
                    max-width: 80px;
                }

                h2 {
                    font-size: 1.75rem;
                    text-align: right;
                    font-weight: 700;
                }
            }
        }

        &__content {
            width: 100%;
            padding: 0 0 120px 0;

            .carousel {
                width: 100%;
                padding: 60px 0;
            }

            .carousel-cell {
                width: 25%;
                margin-right: 20px;
                counter-increment: carousel-cell;

                img {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                    border-radius: 8px;
                    margin-bottom: 15px;
                }

                small {
                    font-size: 12px;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                h2 {
                    padding: 0;
                    margin: 5px 0 0 0;
                    font-size: 20px;
                    color: var(--bs-DarkGrey);
                    font-weight: 600;
                }

                h3 {
                    padding: 0;
                    margin: 0 0 5px 0;
                    font-size: 15px;
                    color: var(--bs-BlueBerry);
                    font-weight: 700;
                }

                p {
                    font-size: 1rem;
                    font-weight: 200;
                }
            }

            .flickity-page-dots {
                z-index: 11;
                position: relative;
            }
        }

        &__divider {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
            transform: rotate(180deg);
        }

        &__divider svg {
            position: relative;
            display: block;
            width: calc(129% + 1.3px);
            height: 163px;
            z-index: 10;
        }

        &__divider .shape-fill {
            fill: #eee;
        }
    }

    &__posts {
        width: 100%;
        padding: 60px 0;
        background-color: #eee;

        &__content {
            width: 100%;
            padding: 30px 0 90px 0;

            .card {
                border-radius: 15px;

                .card-custom-body {
                    width: 100%;
                    height: auto !important;

                    h5 {
                        margin-bottom: 18px;
                    }

                    p {
                        margin-bottom: 20px;
                    }
                }

                .card-body {
                    width: 100%;
                    height: 250px;

                    small {
                        font-size: 14px;
                        font-weight: 500;
                        text-transform: uppercase;
                    }

                    h5 {
                        margin-top: 10px;
                        font-size: 25px;
                        color: var(--bs-DarkGrey);
                        font-weight: 600;
                    }

                    a{
                        text-decoration: none;
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 300;
                        color: var(--bs-DarkGreyVariant);
                    }
                }

                img {
                    width: 100%;
                    border-radius: 15px;
                    padding: 15px;
                    object-fit: cover;
                }
            }
        }
    }
}
