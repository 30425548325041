@media (min-width: 280px) and (max-width: 510px) {
    .home_section_campuses {
        &__container {
            position: relative;

            &__details {
                width: 100% !important;
                max-width: 100% !important;
                background-color: var(--bs-LightGrey) !important;
                margin-top: initial !important;
                margin-left: initial !important;
            }
        }

        &__switch {
            &__item {
                margin-bottom: 1rem !important;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .home_section_campuses {
        &__container {
            &__details {
                margin-left: -50px;
            }
        }
    }
}
