//---------------------------------------------
//
//  Fontes
//
//    1. Carregamento de Mixins
//    2. Carregamento de Fontes
//
//---------------------------------------------

//---------------------------------------------
// 1. Carregamento de Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Carregando fontes
//---------------------------------------------

@include googleFont((
    "name": "ProximaNova",
    "folder": "ProximaNova",
    "files": "ProximaNova",
    "weights": (100, 200, 300, 400, 500, 600, 700)
));

@font-face {
    font-family: 'objetivo-logo-horizontal';
    src:  url('../../fonts/objetivo-logo/objetivo-logo-horizontal.eot?gyt52d');
    src:  url('../../fonts/objetivo-logo/objetivo-logo-horizontal.eot?gyt52d#iefix') format('embedded-opentype'),
    url('../../fonts/objetivo-logo/objetivo-logo-horizontal.ttf?gyt52d') format('truetype'),
    url('../../fonts/objetivo-logo/objetivo-logo-horizontal.woff?gyt52d') format('woff'),
    url('../../fonts/objetivo-logo/objetivo-logo-horizontal.svg?gyt52d#objetivo-logo') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="objetivo-horizontal-"], [class*=" objetivo-horizontal-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'objetivo-logo-horizontal' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.objetivo-horizontal-obj:before {
    content: "\e900";
}

@font-face {
    font-family: 'objetivo-logo-vertical';
    src:  url('../../fonts/objetivo-logo/objetivo-logo-vertical.eot?26aazw');
    src:  url('../../fonts/objetivo-logo/objetivo-logo-vertical.eot?26aazw#iefix') format('embedded-opentype'),
    url('../../fonts/objetivo-logo/objetivo-logo-vertical.ttf?26aazw') format('truetype'),
    url('../../fonts/objetivo-logo/objetivo-logo-vertical.woff?26aazw') format('woff'),
    url('../../fonts/objetivo-logo/objetivo-logo-vertical.svg?26aazw#objetivo-logo') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="objetivo-vertical-"], [class*=" objetivo-vertical-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'objetivo-logo-vertical' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.objetivo-vertical-obj:before {
    content: "\e900";
}

