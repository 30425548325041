@media (min-width: 280px) and (max-width: 510px) {
    .pageHeroPost {
        .container {
            .arrows {
                flex-direction: column;
                margin-top: 30px;
            }
            .arrows li {
            }
            .arrows li::before,
            .arrows li::after {
                display: none;
            }
        }
    }
}
